import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Dimension} from "../components/helper/getDimension"
import NotfoundStyled from './styles/notfoundStyled'

const NotFoundPage = () => {
  const [heightFoot, setHeightFoot] = useState(null)

  useEffect(() => {
    const footerContainer = document.getElementById("footerContainer")
    const footerContainerHeight = footerContainer.clientHeight
    const paddingTotal = Dimension("footer")
    const heightTotal = parseInt(paddingTotal) + footerContainerHeight
    setHeightFoot(heightTotal)

  }, [])


  const headPlusFootHeight = heightFoot + 80
  return <Layout>
          <SEO title="404: Not found" />
          <NotfoundStyled removeH={`${headPlusFootHeight}px`}>
            <h1>404 Page Not Found</h1>
              <section className="error-container">
                <span>4</span>
                <span><span className="screen-reader-text">0</span></span>
                <span>4</span>
              </section>
          </NotfoundStyled>
        </Layout>
}

export default NotFoundPage
