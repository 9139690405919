export const Dimension = (elmID) => {
    var elmPadding, elm = document.getElementById(elmID);
    if(document.all) {// IE
        // elmHeight = elm.currentStyle.height;
        elmPadding = parseInt(elm.currentStyle.paddingTop, 10) + parseInt(elm.currentStyle.paddingBottom, 10) + "px";
    } else {// Mozilla
        // elmHeight = document.defaultView.getComputedStyle(elm, '').getPropertyValue('height');
        elmPadding = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('padding-top')) + parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('padding-bottom')) + "px";
    }
    // const getNumHeight = elmHeight.replace(/\D/g, "");
    const getNumPadding = elmPadding.replace(/\D/g, "");
    return getNumPadding;
}
